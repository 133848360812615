export default function LogoType({
  className,
  variant = "dark",
}: {
  className?: string;
  variant?: "light" | "dark";
}) {
  return variant === "light" ? (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 80 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M62.8322 4.44541C62.5258 3.98588 61.9049 3.8617 61.4454 4.16806C60.9859 4.47441 60.8617 5.09528 61.1681 5.55481L66.6719 13.8106C68.2552 16.1855 71.745 16.1855 73.3283 13.8106L78.8322 5.55481C79.1385 5.09528 79.0143 4.47441 78.5548 4.16806C78.0953 3.8617 77.4744 3.98588 77.1681 4.44541L71.6642 12.7012C70.8726 13.8886 69.1276 13.8886 68.336 12.7012L62.8322 4.44541ZM1 6.00012C1 4.89555 1.89543 4.00012 3 4.00012H15.1429C17.2731 4.00012 19 5.72702 19 7.85726C19 9.83227 17.5156 11.4606 15.6016 11.6874L18.2072 14.293C18.5977 14.6835 18.5977 15.3167 18.2072 15.7072C17.8167 16.0977 17.1835 16.0977 16.793 15.7072L12.8002 11.7144H3V15.0001C3 15.5524 2.55228 16.0001 2 16.0001C1.44772 16.0001 1 15.5524 1 15.0001V6.00012ZM3 9.71441H15.1429C16.1685 9.71441 17 8.88294 17 7.85726C17 6.83159 16.1685 6.00012 15.1429 6.00012H3V9.71441ZM27 4.00012C23.6863 4.00012 21 6.68641 21 10.0001C21 13.3138 23.6863 16.0001 27 16.0001H33C36.3137 16.0001 39 13.3138 39 10.0001C39 6.68641 36.3137 4.00012 33 4.00012H27ZM23 10.0001C23 7.79098 24.7909 6.00012 27 6.00012H33C35.2091 6.00012 37 7.79098 37 10.0001C37 12.2093 35.2091 14.0001 33 14.0001H27C24.7909 14.0001 23 12.2093 23 10.0001ZM41 10.0001C41 6.68641 43.6863 4.00012 47 4.00012H55.5C57.433 4.00012 59 5.56713 59 7.50012C59 9.43312 57.433 11.0001 55.5 11.0001H43.126C43.5701 12.7253 45.1362 14.0001 47 14.0001H58C58.5523 14.0001 59 14.4478 59 15.0001C59 15.5524 58.5523 16.0001 58 16.0001H47C43.6863 16.0001 41 13.3138 41 10.0001ZM43.126 9.00012H55.5C56.3284 9.00012 57 8.32855 57 7.50012C57 6.67169 56.3284 6.00012 55.5 6.00012H47C45.1362 6.00012 43.5701 7.27489 43.126 9.00012Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M65.476 2.64848C65.9464 2.35903 66.5623 2.50569 66.8518 2.97604L69.1485 6.70815C69.539 7.34269 70.4613 7.34269 70.8518 6.70815L73.1485 2.97604C73.4379 2.50569 74.0539 2.35903 74.5242 2.64848C74.9946 2.93793 75.1412 3.55388 74.8518 4.02424L72.5551 7.75635C71.3837 9.65996 68.6166 9.65996 67.4452 7.75635L65.1485 4.02424C64.859 3.55388 65.0057 2.93793 65.476 2.64848Z"
        fill="#F5B51A"
        fillRule="evenodd"
      />
    </svg>
  ) : (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 103 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M71.406 10.2303H55.863C55.8862 9.18825 56.6469 8.10286 57.3045 7.44073C58.0236 6.71678 59.0648 6.38423 60.4797 6.38423H71.0213C74.2766 6.38423 73.8191 10.2303 71.4061 10.2303L71.406 10.2303ZM52.939 11.615C52.939 14.7216 54.319 17.8798 57.1081 19.2162C57.9835 19.6357 59.0532 19.9226 60.3258 19.9226H73.7913C75.6982 19.9226 75.9283 16.9852 73.7175 16.9964L64.4808 16.9995C61.3946 16.9995 58.4354 17.5893 56.9234 15.324C56.5768 14.8048 56.0794 13.9048 56.0168 13.1535H70.9443C72.8337 13.1545 74.177 12.6485 75.3094 11.1326C75.938 10.2912 76.2943 9.16055 76.1803 7.84412C76.0765 6.64668 75.5589 5.72818 74.7724 4.9419C74.3521 4.52167 74.1039 4.30759 73.5367 4.02335C73.1594 3.83427 72.3867 3.53809 71.8676 3.53809H59.7102C57.8406 3.53809 55.8837 4.66154 54.817 6.03095C53.7578 7.39079 52.939 9.24236 52.939 11.615Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M16.6204 11.3082H3.92433C3.41814 11.3082 2.92405 11.0385 2.92405 10.539V6.30826H16.9282C18.3745 6.30826 19.5443 7.36268 19.5443 8.53901C19.5443 9.38734 19.4504 10.1357 18.6565 10.7283C18.0659 11.169 17.6053 11.3082 16.6204 11.3082L16.6204 11.3082ZM5.63249e-05 5.23131V18.0774C5.63249e-05 20.0084 2.09974 20.2316 2.74883 19.0561C3.35674 17.9552 2.18659 14.2312 3.69345 14.2312H15.1584C15.697 14.2312 16.3718 15.2572 16.7159 15.5973L18.5213 17.4077C19.1561 18.1669 19.643 18.5861 20.3322 19.2897C21.3936 20.3732 22.8529 19.4883 22.8529 18.3082C22.8529 17.4606 21.6987 16.5773 20.91 15.7889L18.6979 13.539C20.8843 13.539 22.8531 10.2759 22.256 7.67423C21.6709 5.12483 19.5068 3.38525 16.7742 3.38525H1.69285C0.581217 3.38525 0 4.10656 0 5.23137L5.63249e-05 5.23131Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M28.5474 11.5385C28.5474 8.85514 30.0026 6.30782 32.6255 6.30782H42.7054C48.1147 6.30782 48.1425 16.6923 42.7054 16.6923H32.6255C29.9128 16.6923 28.5474 13.986 28.5474 11.5385ZM25.7004 10.9232C25.7004 13.6245 26.0367 15.3312 27.8006 17.4389C29.0257 18.9027 31.1188 19.6154 33.1641 19.6154H42.1668C45.3515 19.6154 47.8019 18.02 48.9501 15.166C50.9259 10.2547 48.7504 3.38477 42.2437 3.38477H33.0872C29.935 3.38477 27.5129 5.02029 26.3808 7.83422C26.0575 8.63796 25.7004 9.78411 25.7004 10.9232V10.9232Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M78.7148 4.76952C78.7148 5.18389 79.0193 5.70131 79.1912 5.98555C80.8166 8.67335 82.5202 11.8908 84.1444 14.5722C85.2418 16.3839 85.9712 18.3184 87.8699 19.3093C88.7004 19.7427 89.5682 19.9683 90.4243 20.0001H90.851C92.6359 19.9321 94.3334 19.0195 95.4898 17.3855C96.0855 16.5438 100.136 9.29297 101.014 7.83166L102.392 5.43981C102.705 4.74193 102.462 3.99135 101.846 3.56842C101.238 3.15061 100.488 3.32607 100.048 3.71166C99.8742 3.86381 99.598 4.35538 99.4664 4.59193C99.0924 5.26413 98.7564 5.83459 98.3685 6.49435L93.9765 14.1036C93.7906 14.4447 93.6417 14.6993 93.4443 15.033C93.0138 15.7604 92.8661 16.1822 92.0737 16.6628C91.0595 17.2778 89.2305 17.0839 88.4521 16.0351C87.8974 15.2877 84.9172 9.92418 84.0577 8.50522C83.6614 7.85092 83.3508 7.24825 82.9621 6.60043C82.2447 5.40434 81.4306 3.30811 80.2538 3.30811C79.4454 3.30811 78.7148 4.11787 78.7148 4.76963V4.76952Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M97.1823 1.53845C97.1823 -0.0745517 95.1939 -0.445565 94.5112 0.714323L91.5807 5.8616C91.3748 6.21144 91.2064 6.56754 90.6671 6.51157C90.2075 6.46387 90.0004 6.08655 89.8207 5.74398C89.438 5.01456 89.0665 4.38617 88.665 3.66858L87.502 1.6005C87.0026 0.775079 86.8518 0 85.7173 0C85.1498 0 84.7163 0.333287 84.4982 0.7043C83.944 1.64684 84.64 2.42738 85.2319 3.48524C85.825 4.54507 87.543 7.83981 88.1976 8.44338C89.3938 9.54645 91.3103 9.74336 92.5821 8.93957C93.9206 8.09365 94.2519 6.9122 95.1194 5.47617C95.3749 5.05325 95.6072 4.64428 95.8477 4.20429C96.1369 3.67505 97.1821 2.12518 97.1821 1.53845H97.1823Z"
        fill="#F1C232"
        fillRule="evenodd"
      />
    </svg>
  );
}
